import React, { Component } from "react";

export default class footer extends Component {
  render() {
    return (
      <div className="site-footer" id="footer">
        <div className="container">
        Copyright © 2024 <span> @jlbardinas1 </span>
        </div>
      </div>
    );
  }
}
